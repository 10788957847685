
import { mapActions, mapGetters } from 'vuex'
import localized from '~/mixins/localized'

export default {
  mixins: [localized],

  async fetch() {
    await this.getAvailableOpportunities()
  },

  computed: {
    ...mapGetters(['getOpportunities']),

    offersTitleClasses() {
      const offersCount = this.getOpportunities.subscriptions?.length

      if (offersCount >= 3) return 'col-12'

      return `col-12 col-lg-${offersCount * 4}`
    },

    subTitle() {
      let key = 'homepage.pick_subscription.body_top_of_page'

      return this.$t(key, {
        trialDays: this.$store.state.webConfig.config.trialDays,
      })
    },
  },

  methods: {
    ...mapActions(['getAvailableOpportunities']),

    isUnlimited(offer) {
      // We get from reading-hours features "25", "100" or "unlimited"
      //
      // "unlimited" => NaN => true
      // "25" => 25 => false
      // "100" => 100 => false
      return isNaN(Number(offer.features.hours))
    },

    isMultipleProfiles(offer) {
      const maxProfileLogin = offer.features['max-profile-login']

      // Use case for max-profile-login data
      //     "1" => [1]
      // "2 - 4" => [2, 4]
      const numberArray = maxProfileLogin.split('-').map(s => Number(s))

      return numberArray.length > 1
    },

    getPricePerMonthCopy(offer) {
      let key = 'homepage.pick_subscription.price'

      if (this.isMultipleProfiles(offer) && this.isUnlimited(offer)) {
        key = 'homepage.pick_subscription.price.unlimited'
      }

      return this.$t(key, {
        price: this.getlocalizedPrice(offer.variants[0].amount, offer.currency),
      })
    },
  },
}
