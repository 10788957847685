
import { getLocalizedPrice } from '~/helpers/localizationFormatter'

export default {
  computed: {
    image() {
      return {
        mobile: this.$hummingbird.marketProperties.img.homepage.hero_mobile,
        tablet: this.$hummingbird.marketProperties.img.homepage.hero_tablet,
        desktop: this.$hummingbird.marketProperties.img.homepage.hero_desktop,
        position:
          this.$hummingbird.marketProperties.img.homepage.background_position,
      }
    },

    // Because we want to have a one-liner main cta, we need to
    // manage different cta width depending on the text length.
    isLongCallToAction() {
      const ctaLength = this.$t(this.$hummingbird.marketProperties.cta, {
        trialDays: this.$store.state.webConfig.config.trialDays,
      }).length

      return ctaLength >= 26
    },

    /**
     * Computes an array of lines, each containing segments with text and highlight information.
     *
     * Example:
     * Input: "Hello\nmy [brother]"
     * Output: [
     *   {
     *     segments: [
     *       { text: "Hello", isHighlighted: false }
     *     ]
     *   },
     *   {
     *     segments: [
     *       { text: "my ", isHighlighted: false },
     *       { text: "brother", isHighlighted: true }
     *     ]
     *   }
     * ]
     */
    splitTitle() {
      return this.title.split('\n').map(line => {
        const segments = []
        const regex = /\[.*?]|[^[\]]+/g
        let match

        while ((match = regex.exec(line)) !== null) {
          const segmentText = match[0]
          const isHighlighted =
            segmentText.startsWith('[') && segmentText.endsWith(']')
          segments.push({
            text: segmentText.replace(/^\[|\]$/g, ''),
            isHighlighted,
          })
        }

        return { segments }
      })
    },

    // We want to be able to A/B test every possible variation of the hero, so here are some flags that can be toggled:

    title() {
      return this.$t('homepage.hero.title', {
        trialDays: this.$store.state.webConfig.config.trialDays,
      })
    },

    bodyKey() {
      if (this.$store.state.webConfig.config.features.hasMagazines) {
        return 'homepage.hero.body.magazine_market'
      }

      return 'homepage.hero.body'
    },

    ctaButtonKey() {
      // Note: This computed property is duplicated from the one in pages/index.vue for the StickyButton component

      return this.$hummingbird.marketProperties.cta
    },
  },

  methods: {
    getLocalizedPrice,
  },
}
