
export default {
  props: {
    customerData: {
      type: Array,
      required: true,
      validator: function (value) {
        // Check if each item in the array has the required properties to ensure the data is valid
        return value.every(
          item =>
            'stars' in item &&
            'username' in item &&
            'body' in item &&
            'platform' in item
        )
      },
    },
  },
}
