
/**
 * The Konami code component hides content until a specific code is entered.
 * To unlock, press these keys in order, anywhere on the page:
 * ⬆ ⬆ ⬇ ⬇ ⬅ ➡ ⬅ ➡ 🅱 🅰
 *
 * There are two ways to use it:
 *
 * 1. Default slot to show content after the code is entered:
 * <konami-code>
 *   Some super secret content
 * </konami-code>
 *
 * 2. Fallback slot to show content before the code is entered:
 * <konami-code>
 *   <template #fallback>
 *     This text is not secret.
 *   </template>
 *
 *   <template #default>
 *     This text is super secret.
 *   </template>
 * </konami-code>
 *
 * Pro-tip: If you include a component, you can make it Lazy to improve performance.
 * More info here: https://v2.nuxt.com/tutorials/improve-your-developer-experience-with-nuxt-components/#lazy-loading-your-components
 */
export default {
  data() {
    return {
      konamiShow: false,
      konamiPatternCursor: 0,
    }
  },

  mounted() {
    window.addEventListener('keydown', this.konami, false)
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.konami, false)
  },

  methods: {
    konami(event) {
      // the Konami pattern
      const pattern = [
        'ArrowUp',
        'ArrowUp',
        'ArrowDown',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'ArrowLeft',
        'ArrowRight',
        'b',
        'a',
      ]

      // Reset the konamiPatternCursor if the key isn't in the pattern, or isn't the current key in the pattern
      if (
        !pattern.includes(event.key) ||
        event.key !== pattern[this.konamiPatternCursor]
      ) {
        this.konamiPatternCursor = 0
        return
      }

      // check for the next key by increment konamiPatternCursor
      this.konamiPatternCursor++

      // If all the key are checked, show the konami slot and reset konamiPatternCursor
      if (pattern.length === this.konamiPatternCursor) {
        this.konamiPatternCursor = 0
        this.konamiShow = true
      }
    },
  },
}
