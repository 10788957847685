
/**
 * Display a feature list with the needed classes easily.
 * Slot can be used in case an icon must be prepended before each feature.
 */
export default {
  props: {
    features: {
      type: Object,
      default() {
        return {}
      },
    },
    itemClass: {
      type: String,
      default: 'pb-4 pb-md-8',
    },
  },

  computed: {
    featureList() {
      const features = this.features
      const locale = this.$i18n.locale

      const list = [
        this.$tc(
          isNaN(parseInt(features.hours))
            ? 'homepage.pick_subscription.features.hours.unlimited'
            : 'homepage.pick_subscription.features.hours.limited',
          features.hours,
          { n: features.hours }
        ),
        this.$tc(
          'homepage.pick_subscription.features.max_profiles_login',
          features['max-profile-login'],
          { n: features['max-profile-login'] }
        ),
      ]
      // TODO: Need to find a better way of displaying offers features informations
      // INFO: Should be doned in the backend instead of the frontend

      // Because the marketing team wants to remove the
      // number of profiles only for the Finnish Market
      if (!locale.startsWith('fi')) {
        list.splice(
          1,
          0,
          this.$tc(
            'homepage.pick_subscription.features.profiles',
            features.profiles,
            { n: features.profiles }
          )
        )
      }

      return list
    },
  },
}
