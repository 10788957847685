
import unlimitedBrainJuice from '~/assets/images/nextory_unlimited-brainjuice.svg'

export default {
  props: {
    covers: {
      type: Array,
      required: true,
    },

    showUnlimitedBrainJuice: {
      type: Boolean,
      default: false,
    },

    direction: {
      type: String,
      default: 'normal',
    },
  },
  data() {
    return {
      newCovers: [],
    }
  },

  computed: {
    coversList() {
      if (this.showUnlimitedBrainJuice) {
        // Create a new array by spreading the original covers array
        // The scrolling banner is optimized to work with 20 covers,
        // since it is split into 2 arrays, the banner ends up with 10 covers on each row
        const newCovers = [...this.covers]
        // Insert the showUnlimitedBrainJuice at the desired position without mutating the original array
        return [
          ...newCovers.slice(0, 3),
          unlimitedBrainJuice,
          ...newCovers.slice(3),
        ]
      } else {
        // Return a new array with the same elements as the original array
        return [...this.covers]
      }
    },

    speed() {
      // Each cover will take X seconds to appear on the screen.
      return this.coversList.length * 10
    },
  },
}
