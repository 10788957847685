import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6ab46d8d&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeHeading: require('/app/components/HomeHeading/HomeHeading.vue').default,LazyHydrate: require('/app/node_modules/vue-lazy-hydration/dist/LazyHydrate.js').default,HomeHeadingNewHeroBanner: require('/app/components/HomeHeading/HomeHeadingNewHeroBanner.vue').default,ContentIllustratedDetails: require('/app/components/Content/ContentIllustratedDetails.vue').default,KonamiCode: require('/app/components/KonamiCode.vue').default,NxOffers: require('/app/components/Nx/NxOffers.vue').default,ContentCustomerRating: require('/app/components/Content/ContentCustomerRating.vue').default,ContentDivider: require('/app/components/Content/ContentDivider.vue').default,StickyButton: require('/app/components/StickyButton.vue').default})
