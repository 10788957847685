
import { getLocalizedPrice } from '~/helpers/localizationFormatter'

export default {
  data: function () {
    return {
      covers: this.$store.state.webConfig.config.bookCovers,
      coversSplit: {
        firstHalf: [],
        secondHalf: [],
      },
    }
  },
  fetch() {
    //To ensure there'll be 2 rows with a unique set of book covers
    this.splitCovers()
  },
  computed: {
    title() {
      return this.$t('homepage.hero.title.ab_test_new_hero', {
        trialDays: this.$store.state.webConfig.config.trialDays,
      })
    },

    bodyKey() {
      if (this.$store.state.webConfig.config.features.hasMagazines) {
        return 'homepage.hero.body.magazine_market'
      }

      return 'homepage.hero.body.ab_test_new_hero'
    },
  },

  methods: {
    getLocalizedPrice,

    splitCovers() {
      const coversList = [...this.covers]
      const half = Math.ceil(coversList.length / 2)
      this.coversSplit.firstHalf = coversList.slice(0, half)
      this.coversSplit.secondHalf = coversList.slice(half)
    },
  },
}
