
export default {
  data() {
    return {
      // Dynamically generate the array with the same quote key repeated
      quoteArray: this.generateQuotes('homepage.divider.body', 10),
    }
  },

  methods: {
    generateQuotes(quoteKey, times) {
      return Array.from({ length: times }, () => quoteKey)
    },
  },
}
