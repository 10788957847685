
import { getStructuredSearchAction, metaElements } from '~/helpers/metaElements'
import { parseLocaleIso } from '~/helpers/localizationFormatter'

export default {
  layout: 'transparentHeader',

  data() {
    return {
      illustratedDetailsSection: {
        firstElement: {
          title: this.$t('homepage.more_about.title.usp_read_or_listen'),
          details: this.$t(
            this.$store.state.webConfig.config.features.hasMagazines
              ? 'homepage.more_about.body.usp_read_or_listen.magazine_market'
              : 'homepage.more_about.body.usp_read_or_listen'
          ),
          src: this.$hummingbird.marketProperties.img.homepage.more_about_1,
          alt: this.$t('homepage.more_about.img_1.alt'),
        },
        secondElement: {
          title: this.$t('homepage.more_about.title.usp_books_for_family'),
          details: this.$t('homepage.more_about.body.usp_books_for_family'),
          src: this.$hummingbird.marketProperties.img.homepage.more_about_2,
          alt: this.$t('homepage.more_about.img_2.alt'),
          imgLeft: true,
        },
        thirdElement: {
          title: this.$t('homepage.more_about.title.usp_life_better_w_books'),
          details: this.$t('homepage.more_about.body.usp_life_better_w_books'),
          src: this.$hummingbird.marketProperties.img.homepage.more_about_3,
          alt: this.$t('homepage.more_about.img_3.alt'),
        },
      },

      sellingPoints: [
        {
          img: 'web/img/home/usp/logo-first.png',
          alt_img: 'wreath-owl',
          title: this.$t('homepage.usp1.title'),
          text: this.$t('homepage.usp1.body'),
          width: '130',
          height: '60',
        },
        {
          img: 'web/img/home/usp/logo-second.png',
          alt_img: 'wreath-stars',
          title: this.$t('homepage.usp2.title'),
          text: this.$t('homepage.usp2.body'),
          width: '130',
          height: '60',
        },
        {
          img: 'web/img/home/usp/logo-third.png',
          alt_img: 'wreath-challenge',
          title: this.$t('homepage.usp3.title'),
          text: this.$t('homepage.usp3.body'),
          width: '130',
          height: '60',
        },
      ],

      parallaxSectionBackgroundImage:
        'https://assets.nextory.com/web/img/home/paralax/read-listen-parallax.png?auto=format&w=540',

      // Get parallax content for different markets
      // This object will go as a prop to the content-parallax component
      // The content-parallax component will render the content based on the market
      // The properties of this object are the market codes (AT, BE, CH, DE, DK, ES, FI, FR, NL, NO, SE)
      // The values are objects with the following properties:
      // - formats: an array of objects with the following properties:
      //   - position: the position of the SVG phone (left, middle, right) on top of the container parallax__big-picture
      //   - showPhoneControls: whether to show the phone controls or not
      //   - url: the URL of the image
      //   - fullScreenCover: whether the image should be full screen or not
      // - features: an array of objects with the following properties:
      //   - label: the label of the feature
      //   - icon: the icon of the feature

      parallaxContent: {
        AT: {
          formats: [
            {
              position: 'left-phone',
              showPhoneControls: false,
              url: 'https://images.nextory.com/9781781103326.jpg?auto=format&w=182',
            },
            {
              position: 'middle-phone',
              url: 'https://images.nextory.com/9783966351270.jpg?auto=format&w=182',
            },
            {
              position: 'right-phone',
              fullScreenCover: true,
              showPhoneControls: false,
              url: 'https://assets.nextory.com/web/img/home/paralax/AT_ui_phone.png?auto=format&w=192',
            },
          ],
          features: [
            {
              label: this.$t('homepage.this_is_nextory.bullet.audiobooks'),
              icon: 'listen',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.ebooks'),
              icon: 'about',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.childrens_books'),
              icon: 'kid',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.sleep_stories'),
              icon: 'sleep',
            },
          ],
        },

        BE: {
          formats: [
            {
              position: 'left-phone',
              showPhoneControls: false,
              url: 'https://images.nextory.com/11111111111100339478.jpg?auto=format&w=182',
            },
            {
              position: 'middle-phone',
              url: 'https://images.nextory.com/9791036633911.jpg?auto=format&w=182',
            },
            {
              position: 'right-phone',
              fullScreenCover: true,
              showPhoneControls: false,
              url: 'https://assets.nextory.com/web/img/home/paralax/FR_ui_phone.png?auto=format&w=192',
            },
          ],
          features: [
            {
              label: this.$t('homepage.this_is_nextory.bullet.ebooks'),
              icon: 'about',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.audiobooks'),
              icon: 'listen',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.magazines'),
              icon: 'read',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.comics'),
              icon: 'star',
            },
          ],
        },

        CH: {
          formats: [
            {
              position: 'left-phone',
              showPhoneControls: false,
              url: 'https://images.nextory.com/9781781103326.jpg?auto=format&w=182',
            },
            {
              position: 'middle-phone',
              url: 'https://images.nextory.com/9781705085080.jpg?auto=format&w=182',
            },
            {
              position: 'right-phone',
              fullScreenCover: true,
              showPhoneControls: false,
              url: 'https://assets.nextory.com/web/img/home/paralax/CH_ui_phone.png?auto=format&w=192',
            },
          ],
          features: [
            {
              label: this.$t('homepage.this_is_nextory.bullet.audiobooks'),
              icon: 'listen',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.ebooks'),
              icon: 'about',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.childrens_books'),
              icon: 'kid',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.sleep_stories'),
              icon: 'sleep',
            },
          ],
        },

        DE: {
          formats: [
            {
              position: 'left-phone',
              showPhoneControls: false,
              url: 'https://images.nextory.com/9781781103326.jpg?auto=format&w=182',
            },
            {
              position: 'middle-phone',
              url: 'https://images.nextory.com/9781705085080.jpg?auto=format&w=182',
            },
            {
              position: 'right-phone',
              fullScreenCover: true,
              showPhoneControls: false,
              url: 'https://assets.nextory.com/web/img/home/paralax/DE_ui_phone.png?auto=format&w=192',
            },
          ],
          features: [
            {
              label: this.$t('homepage.this_is_nextory.bullet.audiobooks'),
              icon: 'listen',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.ebooks'),
              icon: 'about',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.childrens_books'),
              icon: 'kid',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.sleep_stories'),
              icon: 'sleep',
            },
          ],
        },

        DK: {
          formats: [
            {
              position: 'left-phone',
              url: 'https://images.nextory.com/9788740068832.jpg?auto=format&w=182',
            },
            {
              position: 'middle-phone',
              url: 'https://images.nextory.com/9781781108765.jpg?auto=format&w=182',
            },
            {
              position: 'right-phone',
              fullScreenCover: true,
              showPhoneControls: false,
              url: 'https://assets.nextory.com/web/img/home/paralax/DK_ui_phone.png?auto=format&w=192',
            },
          ],
          features: [
            {
              label: this.$t('homepage.this_is_nextory.bullet.audiobooks'),
              icon: 'listen',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.ebooks'),
              icon: 'about',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.childrens_books'),
              icon: 'kid',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.sleep_stories'),
              icon: 'sleep',
            },
          ],
        },

        ES: {
          formats: [
            {
              position: 'left-phone',
              showPhoneControls: false,
              url: 'https://images.nextory.com/2024013113082.jpg?auto=format&w=182',
            },
            {
              position: 'middle-phone',
              url: 'https://images.nextory.com/9788467073454.jpg?auto=format&w=182',
            },
            {
              position: 'right-phone',
              fullScreenCover: true,
              showPhoneControls: false,
              url: 'https://assets.nextory.com/web/img/home/paralax/ES_ui_phone.png?auto=format&w=192',
            },
          ],
          features: [
            {
              label: this.$t('homepage.this_is_nextory.bullet.ebooks'),
              icon: 'about',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.magazines'),
              icon: 'read',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.audiobooks'),
              icon: 'listen',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.planeta'),
              icon: 'referral',
            },
          ],
        },

        FI: {
          formats: [
            {
              position: 'left-phone',
              url: 'https://images.nextory.com/9789510481264.jpg?auto=format&w=182',
            },
            {
              position: 'middle-phone',
              url: 'https://images.nextory.com/9781781108765.jpg?auto=format&w=182',
            },
            {
              position: 'right-phone',
              fullScreenCover: true,
              showPhoneControls: false,
              url: 'https://assets.nextory.com/web/img/home/paralax/FI_ui_phone.png?auto=format&w=192',
            },
          ],
          features: [
            {
              label: this.$t('homepage.this_is_nextory.bullet.audiobooks'),
              icon: 'listen',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.ebooks'),
              icon: 'about',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.childrens_books'),
              icon: 'kid',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.sleep_stories'),
              icon: 'sleep',
            },
          ],
        },

        FR: {
          formats: [
            {
              position: 'left-phone',
              showPhoneControls: false,
              url: 'https://images.nextory.com/11111111111100339478.jpg?auto=format&w=182',
            },
            {
              position: 'middle-phone',
              url: 'https://images.nextory.com/9791036633911.jpg?auto=format&w=182',
            },
            {
              position: 'right-phone',
              fullScreenCover: true,
              showPhoneControls: false,
              url: 'https://assets.nextory.com/web/img/home/paralax/FR_ui_phone.png?auto=format&w=192',
            },
          ],
          features: [
            {
              label: this.$t('homepage.this_is_nextory.bullet.ebooks'),
              icon: 'about',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.audiobooks'),
              icon: 'listen',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.magazines'),
              icon: 'read',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.comics'),
              icon: 'star',
            },
          ],
        },

        NL: {
          formats: [
            {
              position: 'left-phone',
              showPhoneControls: false,
              url: 'https://images.nextory.com/9781781103326.jpg?auto=format&w=182',
            },
            {
              position: 'middle-phone',
              url: 'https://images.nextory.com/9781781103326.jpg?auto=format&w=182',
            },
            {
              position: 'right-phone',
              fullScreenCover: true,
              showPhoneControls: false,
              url: 'https://assets.nextory.com/web/img/home/paralax/NL_ui_phone.png?auto=format&w=192',
            },
          ],
          features: [
            {
              label: this.$t('homepage.this_is_nextory.bullet.audiobooks'),
              icon: 'listen',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.ebooks'),
              icon: 'about',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.childrens_books'),
              icon: 'kid',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.sleep_stories'),
              icon: 'sleep',
            },
          ],
        },

        NO: {
          formats: [
            {
              position: 'left-phone',
              url: 'https://images.nextory.com/9788242979681.jpg?auto=format&w=182',
            },
            {
              position: 'middle-phone',
              url: 'https://images.nextory.com/9781781108765.jpg?auto=format&w=182',
            },
            {
              position: 'right-phone',
              fullScreenCover: true,
              showPhoneControls: false,
              url: 'https://assets.nextory.com/web/img/home/paralax/NO_ui_phone.png?auto=format&w=192',
            },
          ],
          features: [
            {
              label: this.$t('homepage.this_is_nextory.bullet.audiobooks'),
              icon: 'listen',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.ebooks'),
              icon: 'about',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.childrens_books'),
              icon: 'kid',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.sleep_stories'),
              icon: 'sleep',
            },
          ],
        },

        SE: {
          formats: [
            {
              position: 'left-phone',
              showPhoneControls: false,
              url: 'https://images.nextory.com/11111111111002098538.jpg?auto=format&w=182',
            },
            {
              position: 'middle-phone',
              url: 'https://images.nextory.com/9789177953241.jpg?auto=format&w=182',
            },
            {
              position: 'right-phone',
              fullScreenCover: true,
              showPhoneControls: false,
              url: 'https://assets.nextory.com/web/img/home/paralax/SE_ui_phone.png?auto=format&w=192',
            },
          ],
          features: [
            {
              label: this.$t('homepage.this_is_nextory.bullet.audiobooks'),
              icon: 'listen',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.ebooks'),
              icon: 'about',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.magazines'),
              icon: 'read',
            },
            {
              label: this.$t('homepage.this_is_nextory.bullet.childrens_books'),
              icon: 'kid',
            },
          ],
        },
      },
      userReviewSection: {
        reviewText: this.$t('homepage.testimonial.quote'),
        userName: this.$t('homepage.testimonial.name'),
      },

      imageUrls: {
        tablet: 'web/img/home/showcase/girl-listening-to-audiobook-tablet.jpg',
        desktop:
          'web/img/home/showcase/girl-listening-to-audiobook-desktop.jpg',
      },

      showcaseContent: {
        FR: [
          'https://images.nextory.com/9791039539524.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9782075038676.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9791036605062.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9782075136181.jpg?fit=clip&auto=format&w=160&h=160',
        ],
        BE: [
          'https://images.nextory.com/9791039539524.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9782075038676.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9791036605062.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9782075136181.jpg?fit=clip&auto=format&w=160&h=160',
        ],
        DE: [
          'https://images.nextory.com/9783844935370.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9783754009970.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9783837367058.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9783844937695.jpg?fit=clip&auto=format&w=160&h=160',
        ],
        AT: [
          'https://images.nextory.com/9788728335406.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9783844915969.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9783844933628.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9780755502554.jpg?fit=clip&auto=format&w=160&h=160',
        ],
        CH: [
          'https://images.nextory.com/4057664879806.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/4066004497297_1400.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9783844926088.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9781685085377.jpg?fit=clip&auto=format&w=160&h=160',
        ],
        ES: [
          'https://images.nextory.com/9788418609725.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9788408275268.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9788427052840.jpg?fit=crop&auto=format&w=300&h=300&crop=top',
          'https://images.nextory.com/9798886576320.jpg?fit=clip&auto=format&w=160&h=160',
        ],
        NL: [
          'https://images.nextory.com/9789025882631.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9789021425771.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9789021682402.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9781685085377.jpg?fit=clip&auto=format&w=160&h=160',
        ],
        SE: [
          'https://images.nextory.com/9789179793913.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9789178270675.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9789179796006.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9789180236560.jpg?fit=clip&auto=format&w=160&h=160',
        ],
        DK: [
          'https://images.nextory.com/9788727085487.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9788740080940.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9788711374757.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9788775623006.jpg?fit=clip&auto=format&w=160&h=160',
        ],
        NO: [
          'https://images.nextory.com/9788202412852.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9788248925743.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9788242144331.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9788711409046.jpg?fit=clip&auto=format&w=160&h=160',
        ],
        FI: [
          'https://images.nextory.com/9789511484226.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9789520409555.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9789510485804.jpg?fit=clip&auto=format&w=160&h=160',
          'https://images.nextory.com/9789511469445.jpg?fit=clip&auto=format&w=160&h=160',
        ],
      },
      links: [
        {
          text: this.$t('homepage.showcase.link_1.family'),
          slugTitle: this.$t('homepage.showcase.link_1.slugtitle'),
          slugId: '111',
        },
        {
          text: this.$t('homepage.showcase.link_2.family'),
          slugTitle: this.$t('homepage.showcase.link_2.slugtitle'),
          slugId: '126',
        },
        {
          text: this.$t('homepage.showcase.link_3.family'),
          slugTitle: this.$t('homepage.showcase.link_3.slugtitle'),
          slugId: '141',
        },
        {
          text: this.$t('homepage.showcase.link_4.family'),
          slugTitle: this.$t('homepage.showcase.link_4.slugtitle'),
          slugId: '265',
        },
      ],
    }
  },

  head() {
    const title = this.$t('meta.home.title', {
      trialDays: this.$store.state.webConfig.config.trialDays,
    })
    const description = this.$t('meta.home.description', {
      trialDays: this.$store.state.webConfig.config.trialDays,
    })

    const elements = metaElements(title, description)

    // Mark page as not indexable if any AB test flag is present in the query
    // This is done to avoid duplicate content issues
    const abTestFlags = ['sumCopy', 'sumHero', 'sumBanner', 'futureHero']
    if (abTestFlags.some(flag => this.$route.query[flag] !== undefined)) {
      elements.meta.push({
        name: 'robots',
        content: 'noindex',
      })
    }

    return elements
  },

  computed: {
    // This computed property is used to get the customer data (translations) for the customer rating section
    customerData() {
      const markets = {
        AT: 'germany',
        BE: 'france',
        CH: 'germany',
        DE: 'germany',
        DK: 'denmark',
        ES: 'spain',
        FI: 'finland',
        FR: 'france',
        NL: 'netherlands',
        NO: 'norway',
        SE: 'sweden',
      }

      const translations = {
        AT: [
          {
            stars: 'homepage.review.germany_1.stars',
            username: 'homepage.review.germany_1.username',
            body: 'homepage.review.germany_1.body',
            platform: 'homepage.review.germany_1.platform',
          },
          {
            stars: 'homepage.review.germany_2.stars',
            username: 'homepage.review.germany_2.username',
            body: 'homepage.review.germany_2.body',
            platform: 'homepage.review.germany_2.platform',
          },
          {
            stars: 'homepage.review.germany_3.stars',
            username: 'homepage.review.germany_3.username',
            body: 'homepage.review.germany_3.body',
            platform: 'homepage.review.germany_3.platform',
          },
          {
            stars: 'homepage.review.germany_4.stars',
            username: 'homepage.review.germany_4.username',
            body: 'homepage.review.germany_4.body',
            platform: 'homepage.review.germany_4.platform',
          },
        ],
        BE: [
          {
            stars: 'homepage.review.france_1.stars',
            username: 'homepage.review.france_1.username',
            body: 'homepage.review.france_1.body',
            platform: 'homepage.review.france_1.platform',
          },
          {
            stars: 'homepage.review.france_2.stars',
            username: 'homepage.review.france_2.username',
            body: 'homepage.review.france_2.body',
            platform: 'homepage.review.france_2.platform',
          },
          {
            stars: 'homepage.review.france_3.stars',
            username: 'homepage.review.france_3.username',
            body: 'homepage.review.france_3.body',
            platform: 'homepage.review.france_3.platform',
          },
          {
            stars: 'homepage.review.france_4.stars',
            username: 'homepage.review.france_4.username',
            body: 'homepage.review.france_4.body',
            platform: 'homepage.review.france_4.platform',
          },
        ],
        CH: [
          {
            stars: 'homepage.review.germany_1.stars',
            username: 'homepage.review.germany_1.username',
            body: 'homepage.review.germany_1.body',
            platform: 'homepage.review.germany_1.platform',
          },
          {
            stars: 'homepage.review.germany_2.stars',
            username: 'homepage.review.germany_2.username',
            body: 'homepage.review.germany_2.body',
            platform: 'homepage.review.germany_2.platform',
          },
          {
            stars: 'homepage.review.germany_3.stars',
            username: 'homepage.review.germany_3.username',
            body: 'homepage.review.germany_3.body',
            platform: 'homepage.review.germany_3.platform',
          },
          {
            stars: 'homepage.review.germany_4.stars',
            username: 'homepage.review.germany_4.username',
            body: 'homepage.review.germany_4.body',
            platform: 'homepage.review.germany_4.platform',
          },
        ],
        DE: [
          {
            stars: 'homepage.review.germany_1.stars',
            username: 'homepage.review.germany_1.username',
            body: 'homepage.review.germany_1.body',
            platform: 'homepage.review.germany_1.platform',
          },
          {
            stars: 'homepage.review.germany_2.stars',
            username: 'homepage.review.germany_2.username',
            body: 'homepage.review.germany_2.body',
            platform: 'homepage.review.germany_2.platform',
          },
          {
            stars: 'homepage.review.germany_3.stars',
            username: 'homepage.review.germany_3.username',
            body: 'homepage.review.germany_3.body',
            platform: 'homepage.review.germany_3.platform',
          },
          {
            stars: 'homepage.review.germany_4.stars',
            username: 'homepage.review.germany_4.username',
            body: 'homepage.review.germany_4.body',
            platform: 'homepage.review.germany_4.platform',
          },
        ],
        DK: [
          {
            stars: 'homepage.review.denmark_1.stars',
            username: 'homepage.review.denmark_1.username',
            body: 'homepage.review.denmark_1.body',
            platform: 'homepage.review.denmark_1.platform',
          },
          {
            stars: 'homepage.review.denmark_2.stars',
            username: 'homepage.review.denmark_2.username',
            body: 'homepage.review.denmark_2.body',
            platform: 'homepage.review.denmark_2.platform',
          },
          {
            stars: 'homepage.review.denmark_3.stars',
            username: 'homepage.review.denmark_3.username',
            body: 'homepage.review.denmark_3.body',
            platform: 'homepage.review.denmark_3.platform',
          },
          {
            stars: 'homepage.review.denmark_4.stars',
            username: 'homepage.review.denmark_4.username',
            body: 'homepage.review.denmark_4.body',
            platform: 'homepage.review.denmark_4.platform',
          },
        ],
        ES: [
          {
            stars: 'homepage.review.spain_1.stars',
            username: 'homepage.review.spain_1.username',
            body: 'homepage.review.spain_1.body',
            platform: 'homepage.review.spain_1.platform',
          },
          {
            stars: 'homepage.review.spain_2.stars',
            username: 'homepage.review.spain_2.username',
            body: 'homepage.review.spain_2.body',
            platform: 'homepage.review.spain_2.platform',
          },
          {
            stars: 'homepage.review.spain_3.stars',
            username: 'homepage.review.spain_3.username',
            body: 'homepage.review.spain_3.body',
            platform: 'homepage.review.spain_3.platform',
          },
          {
            stars: 'homepage.review.spain_4.stars',
            username: 'homepage.review.spain_4.username',
            body: 'homepage.review.spain_4.body',
            platform: 'homepage.review.spain_4.platform',
          },
        ],
        FI: [
          {
            stars: 'homepage.review.finland_1.stars',
            username: 'homepage.review.finland_1.username',
            body: 'homepage.review.finland_1.body',
            platform: 'homepage.review.finland_1.platform',
          },
          {
            stars: 'homepage.review.finland_2.stars',
            username: 'homepage.review.finland_2.username',
            body: 'homepage.review.finland_2.body',
            platform: 'homepage.review.finland_2.platform',
          },
          {
            stars: 'homepage.review.finland_3.stars',
            username: 'homepage.review.finland_3.username',
            body: 'homepage.review.finland_3.body',
            platform: 'homepage.review.finland_3.platform',
          },
          {
            stars: 'homepage.review.finland_4.stars',
            username: 'homepage.review.finland_4.username',
            body: 'homepage.review.finland_4.body',
            platform: 'homepage.review.finland_4.platform',
          },
        ],
        FR: [
          {
            stars: 'homepage.review.france_1.stars',
            username: 'homepage.review.france_1.username',
            body: 'homepage.review.france_1.body',
            platform: 'homepage.review.france_1.platform',
          },
          {
            stars: 'homepage.review.france_2.stars',
            username: 'homepage.review.france_2.username',
            body: 'homepage.review.france_2.body',
            platform: 'homepage.review.france_2.platform',
          },
          {
            stars: 'homepage.review.france_3.stars',
            username: 'homepage.review.france_3.username',
            body: 'homepage.review.france_3.body',
            platform: 'homepage.review.france_3.platform',
          },
          {
            stars: 'homepage.review.france_4.stars',
            username: 'homepage.review.france_4.username',
            body: 'homepage.review.france_4.body',
            platform: 'homepage.review.france_4.platform',
          },
        ],
        NL: [
          {
            stars: 'homepage.review.netherlands_1.stars',
            username: 'homepage.review.netherlands_1.username',
            body: 'homepage.review.netherlands_1.body',
            platform: 'homepage.review.netherlands_1.platform',
          },
          {
            stars: 'homepage.review.netherlands_2.stars',
            username: 'homepage.review.netherlands_2.username',
            body: 'homepage.review.netherlands_2.body',
            platform: 'homepage.review.netherlands_2.platform',
          },
          {
            stars: 'homepage.review.netherlands_3.stars',
            username: 'homepage.review.netherlands_3.username',
            body: 'homepage.review.netherlands_3.body',
            platform: 'homepage.review.netherlands_3.platform',
          },
          {
            stars: 'homepage.review.netherlands_4.stars',
            username: 'homepage.review.netherlands_4.username',
            body: 'homepage.review.netherlands_4.body',
            platform: 'homepage.review.netherlands_4.platform',
          },
        ],
        NO: [
          {
            stars: 'homepage.review.norway_1.stars',
            username: 'homepage.review.norway_1.username',
            body: 'homepage.review.norway_1.body',
            platform: 'homepage.review.norway_1.platform',
          },
          {
            stars: 'homepage.review.norway_2.stars',
            username: 'homepage.review.norway_2.username',
            body: 'homepage.review.norway_2.body',
            platform: 'homepage.review.norway_2.platform',
          },
          {
            stars: 'homepage.review.norway_3.stars',
            username: 'homepage.review.norway_3.username',
            body: 'homepage.review.norway_3.body',
            platform: 'homepage.review.norway_3.platform',
          },
          {
            stars: 'homepage.review.norway_4.stars',
            username: 'homepage.review.norway_4.username',
            body: 'homepage.review.norway_4.body',
            platform: 'homepage.review.norway_4.platform',
          },
        ],
        SE: [
          {
            stars: 'homepage.review.sweden_1.stars',
            username: 'homepage.review.sweden_1.username',
            body: 'homepage.review.sweden_1.body',
            platform: 'homepage.review.sweden_1.platform',
          },
          {
            stars: 'homepage.review.sweden_2.stars',
            username: 'homepage.review.sweden_2.username',
            body: 'homepage.review.sweden_2.body',
            platform: 'homepage.review.sweden_2.platform',
          },
          {
            stars: 'homepage.review.sweden_3.stars',
            username: 'homepage.review.sweden_3.username',
            body: 'homepage.review.sweden_3.body',
            platform: 'homepage.review.sweden_3.platform',
          },
          {
            stars: 'homepage.review.sweden_4.stars',
            username: 'homepage.review.sweden_4.username',
            body: 'homepage.review.sweden_4.body',
            platform: 'homepage.review.sweden_4.platform',
          },
        ],
      }

      const { country } = parseLocaleIso(this.$i18n.localeProperties.iso)
      const keys = translations[country]

      return keys.map(key => {
        return {
          stars: Number(this.$t(key.stars)),
          username: this.$t(key.username),
          body: this.$t(key.body),
          platform: this.$t(key.platform),
        }
      })
    },

    ctaButtonKey() {
      return this.$hummingbird.marketProperties.cta
    },

    // The future hero has scrolling books, it's hidden by default
    // ℹ Query parameter: futureHero
    useFutureHero() {
      if (this.$route.query.futureHero === 'false') {
        return false
      }

      if (
        this.$route.query.futureHero === 'true' &&
        this.$store.state.webConfig?.config?.bookCovers?.length > 0
      ) {
        return true
      }

      return this.$hummingbird.marketProperties.img.homepage.use_future_hero
    },
  },

  methods: {
    // This method returns content based on the specified content type and the current country.
    getContentByCountry(contentType) {
      const { country } = parseLocaleIso(this.$i18n.localeProperties.iso)
      return this[contentType][country] || {}
    },
  },

  jsonld() {
    return getStructuredSearchAction(this.$nuxt.context)
  },
}
